<template lang="pug">
include ../../../configs/template.pug
div.row.mt-4.text-left
  div(v-if="!$route.params?.documentID").col-12
    div.row
      div.col-12
        +select-validation('body.rank', 'ranksSQC', '', 'nameLang', '["required"]')(
          @input="enterDoublePosition"
          :label="`${$t('qualification')} - ${$t('rank')} *`"
          :placeholder="`${$t('qualification')} - ${$t('rank')}`")
      div.col-12
        +select-validation('body.list_positions', 'positionsByIdSQC(body.rank)', 'position', 'nameLang', '["required"]')(
          @change="removePosition" multiple)
      div.col-12
        +select-validation('typeSQC', 'listTypeSQC', 'typeSQC', 'nameLang', '["required"]')
  div(v-else).col-12
    div.row
      div(v-if="checkAccess('sailorSQCStatement', 'editDateMeeting', statement)").col-12
        +date-picker-validation('body.date_meeting','dataEvent','dateMeetingObject','["validDateMeeting"]')(
          @input="ChooseDateCalendare"
          :locale="lang"
          :min="minDateMeetingValue.toISOString()"
          :max="getDateInPeriod(365/2, 'increase').toISOString()"
          start-weekday="1"
          button-only
          :allowed-dates="allowedDates"
          right)
      div(v-if="checkAccess('backOffice')").col-12 {{ $t('freeSpacesAmount') }}: {{ freeSpacesAmount }}
  div.col-12
    div.row
      div.col-12.px-0
        FileDropZone(ref="mediaContent" v-if="checkAccess('sailorSQCStatement', 'add_file')").col-12
      div.col-12.d-flex.justify-center.mt-3
        v-btn(@click="saveNewApplicationSQC" :loading="isLoading" color="success") {{ $t('btn.save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { clearBody, getDateInPeriod } from '@/mixins/main'
import { SUCCESS_CODE } from '@/configs/constants'
import { sailorStatementSQCAdding } from '@/mixins/validationRules'
const initBody = () => ({
  is_payed: false,
  rank: null,
  list_positions: null,
  date_meeting: null
})
export default {
  name: 'FormCreateEditStatementSQC',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: { statement: { type: Object, default: () => ({}) } },
  data () {
    return {
      getDateInPeriod,
      body: initBody(),
      typeSQC: null,
      isLoading: false,
      checkAccess,
      freeSpacesAmount: null
    }
  },
  computed: {
    ...mapGetters(['ranksSQC', 'positionsByIdSQC']),
    ...mapState({
      id: state => state.sailor.sailorId,
      lang: state => state.main.lang,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listTypeSQC: state => state.directory.listTypeSQC.filter(el => ![1, 4, 5].includes(el.id))
    }),
    minDateMeetingValue () {
      if (checkAccess('backOffice')) return new Date()
      else {
        if (this.statement.date_meeting) return new Date(this.statement.date_meeting)
        else return getDateInPeriod(1, 'increase')
      }
    },
    dateMeetingObject () {
      return this.body.date_meeting ? new Date(this.body.date_meeting) : null
    }
  },
  validations () { return sailorStatementSQCAdding(this) },
  mounted () {
    if (this.$route.params.documentID) {
      this.body.date_meeting = this.statement.date_meeting
      this.body.is_payed = this.statement.is_payed
      this.checkFreeSpacesAmount()
    }
  },
  methods: {
    ...mapActions(['setSQCStatements', 'updateSQCStatements', 'getSQCStatements', 'getFreeSpacesAmount']),

    async saveNewApplicationSQC () {
      if (this.$v.$invalid) return this.$v.$touch()
      let data = {
        ...this.$route.params,
        body: { ...clearBody({ ...this.body }) },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_sqc'
        }
      }

      if (this.typeSQC === 2) data.body.is_continue = 3
      else if (this.body.typeSQC === 3) data.ces_exam = true

      this.isLoading = true
      const response = await this[`${this.$route.params?.documentID ? 'update' : 'set'}SQCStatements`](data)
      if (SUCCESS_CODE.includes(response?.code)) {
        if (this.$route.params.documentID) {
          this.$notification.success('changedStatementSQC')
        } else this.$parent.isViewAddSlot = false
        this.body = initBody()
        this.$v.$reset()
      }
      this.isLoading = false
    },
    async ChooseDateCalendare (item) {
      this.freeSpacesAmount = (await this.getFreeSpacesAmount({ branchOffice: this.statement.branch_office, dateMeeting: item }))?.data?.count_free_places
    },
    enterDoublePosition () {
      this.body.list_positions = []
      if (this.body?.rank) {
        const doublePositions = [106, 121, 122, 123]
        if (doublePositions.includes(this.body.rank)) {
          this.positionsByIdSQC(this.body.rank).forEach(value => {
            this.body.list_positions.push(value.id)
          })
        }
      }
    },
    removePosition () {
      if ([106, 121, 122, 123].includes(this.body.rank) && this.body.list_positions.length < 2) {
        this.enterDoublePosition()
      }
    },
    checkFreeSpacesAmount () {
      if (checkAccess('backOffice') && this.body.date_meeting) {
        clearTimeout(this.delayProp)
        this.delayProp = setTimeout(async () => {
          const response = await this.getFreeSpacesAmount({ branchOffice: this.statement.branch_office, dateMeeting: this.body.date_meeting })
          this.freeSpacesAmount = response.data.count_free_places
        }, 1000)
      }
    },
    allowedDates (val) {
      let newArray = this.statement?.disabled_dated ? [...new Set(this.statement?.disabled_dated?.flat(2))] : []
      const weekday = new Date(val).getDay()
      const isWeekend = weekday === 0 || weekday === 6
      return !newArray.some(el => el === val) && !isWeekend
    }
  }
}
</script>
